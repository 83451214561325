<template>
  <div></div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';

export default defineComponent({
  name: 'CorpNiceAuth',

  data() {
    return {
      birthdate: ''
    };
  },

  mounted() {
    const check = this.$cookies.get('checkChangeTel');
    if (check === 'Y') {
      this.getNiceAuth2();
    } else {
      this.getNiceAuth();
    }
  },

  methods: {
    triggerChange() {
      const input = opener.document.querySelector('#birthDate');
      if (input) {
        input.dispatchEvent(new Event('change'));
      }
    },
    getNiceAuth() {
      const encryptString = window.location.href;

      axios
        .post(`/api/nice/v2/niceDecryptVal`, {
          encryptString
        })
        .then(res => {
          const { data } = res;
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            const { birthDate } = result;
            const today = new Date();
            const yyyy = today.getFullYear();
            const mm = today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1).toString(); //getMonth()
            const dd = today.getDate() < 10 ? '0' + today.getDate() : today.getDate().toString(); //
            const isChild = parseInt(yyyy + mm + dd) - parseInt(birthDate) - 190000;

            if (isChild > 0) {
              opener.document.getElementById('birthDate').value = birthDate;
              opener.document.getElementById('encryptString').value = encryptString;
              this.triggerChange();
              window.close();
            } else {
              alert('만 19세 미만 미성년자는 회원가입을 할 수 없습니다.');
            }
          }
        });
    },

    async getNiceAuth2() {
      const encryptString = window.location.href;
      const corpId = this.$cookies.get('corpId');
      await axios.post('/api/nice/v2/niceDecryptVal', { encryptString }).then(res => {
        const { data } = res;
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const { birthDate } = result;
          const today = new Date();
          const yyyy = today.getFullYear();
          const mm = today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1).toString();
          const dd = today.getDate() < 10 ? '0' + today.getDate() : today.getDate().toString();
          const isChild = parseInt(yyyy + mm + dd) - parseInt(birthDate) - 190000;

          if (isChild > 0) {
            axios
              .post('/api/corp/v2/updateCorp', {
                encryptString,
                corpId
              })
              .then(res => {
                if (res.status === 200) {
                  opener.document.getElementById('refresh').click();
                  this.$cookies.remove('checkChangeTel');
                  window.close();
                }
              });
          } else {
            alert('만 19세 미만 미성년자는 회원가입을 할 수 없습니다.');
          }
        }
      });
    }
  }
});
</script>
